import React, { useState } from "react";
import styled from "styled-components";
import Sliderphotos from "../../../quienessomos/Historia/Sliderphotos";

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;

  @media (max-width: 900px) {
    display: none;
  }
`;

const SliderContainerResponsive = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;

  @media (max-width: 900px) {
    display: flex;
  }
  @media (max-width: 500px) {
    display: flex;
    max-height: 300px;

  }
`;

const LargeImage = styled.img`
  width: 30%;
  height: 400px;
  object-fit: cover;
`;

const SmallImage = styled.img`
  width: 15%;
  height: 250px;
  object-fit: cover;
  margin: 0 25px;
  cursor: pointer;
`;

const Arrow = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 20px;
  background-color: var(--celeste);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const LeftArrow = styled(Arrow)`
  left: 20px;
  color: white;
`;

const RightArrow = styled(Arrow)`
  right: 20px;
  color: white;
`;

const Slidercrecer = ({ galeria }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const images = galeria.map((item, index) => {
    return item.fields.file.url;
  });

  const handleImageClick = (index) => {
    setActiveIndex(index);
  };

  const handlePrevImage = () => {
    const newIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    setActiveIndex(newIndex);
  };

  const handleNextImage = () => {
    const newIndex = (activeIndex + 1) % images.length;
    setActiveIndex(newIndex);
  };

  return (
    <>
      <SliderContainer>
        <LeftArrow onClick={handlePrevImage}>{"<"}</LeftArrow>

        <SmallImage
          src={images[activeIndex === 0 ? images.length - 1 : activeIndex - 1]}
          onClick={() =>
            handleImageClick(
              activeIndex === 0 ? images.length - 1 : activeIndex - 1
            )
          }
        />

        <LargeImage src={images[activeIndex]} />

        <SmallImage
          src={images[(activeIndex + 1) % images.length]}
          onClick={() => handleImageClick((activeIndex + 1) % images.length)}
        />

        <RightArrow onClick={handleNextImage}>{">"}</RightArrow>
      </SliderContainer>
      <SliderContainerResponsive>
        <Sliderphotos galeria={galeria}></Sliderphotos>
      </SliderContainerResponsive>
    </>
  );
};

export default Slidercrecer;
