import * as React from "react";
import styled from "styled-components";
import BoldContentful from "../../../../utils/BoldContentful";

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 300px;
  justify-content: center;
  position: relative;
  margin: 0px 0px 0px 0px;

  @media (max-width: 900px) {
    height: auto;
    margin-top: 48px;
  }
`;

const Subcontainer = styled.div`
  width: 90%;
  max-width: 1440px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Titulocontent = styled.div`
  color: ${(p) => p.c};
  font-size: 24px;
  font-weight: 700;
  width: 70%;
  margin-bottom: 12px;
  text-align: center;
`;
const Textocontent = styled.div`
  color: var(--gris);
  font-weight: 400;
  width: 70%;
  margin-bottom: 70px;
  text-align: center;
`;

const Bottomsection = ({ titulo, subtitulo, clr }) => {
  return (
    <Container>
      <Subcontainer>
        <Titulocontent c={clr}>{BoldContentful(titulo, clr)}</Titulocontent>
        <Textocontent>{BoldContentful(subtitulo, "var(--gris)")}</Textocontent>
      </Subcontainer>
    </Container>
  );
};
export default Bottomsection;
