import * as React from "react";
import styled from "styled-components";
import Layout from "../../../../components/Layout/Layout.js";
import useContentful from "../../../../utils/useContentful";
import useContentful2 from "../../../../utils/useContentful2";
import { Link } from "gatsby";
import MetaTags from "react-meta-tags";
import Titlerich from "../../../../components/Titlerich.js";
import Sideimage from "../../../../components/proyectos/pais/Sideimage.js";
import Historianorich from "../../../../components/proyectos/pais/crecer/Historianorich.js";
import Historianorichright from "../../../../components/proyectos/pais/crecer/Historianorichright.js";
import Slidercrecer from "../../../../components/proyectos/pais/crecer/Slidercrecer.js";
import Bottomsection from "../../../../components/proyectos/pais/crecer/Bottomsection.js";
import Textrichcrecer from "../../../../components/proyectos/pais/crecer/Textrichcrecer.js";
import Textrichcrecerintro from "../../../../components/proyectos/pais/crecer/Textrichcrecerintro.js";
import "../../../../styles/global.css";
import "../../../../styles/breadcrums.css";
import { Helmet } from "react-helmet";
import Home from "../../../../images/Home/home.png";
import Education from "../../../../images/Home/educacion_morado.svg";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;
const ImgEducacion = styled.img`
  width: 120px;
  height: auto;
  @media (max-width: 500px) {
    margin-top: 3em;
  }
`;

const Contentbar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5%;
  img {
    width: 160px;
    height: 100px;
  }
`;

const Cardcontainer = styled.div`
  padding: 0px 3%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;

  img {
    width: 80%;
  }
  @media (max-width: 900px) {
    padding: 0px 0%;

    img {
      width: 100%;
      margin-bottom: 30px;
    }
  }
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const CrecerEntry = "5s2EGBOQUZl5qYFjO5jMXB";

const IndexPage = ({ location }) => {
  const { data: crecer, fetched } = useContentful2({ id: CrecerEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });

 

  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title>Crecer</title>
            <meta name="description" content="Crecer" />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/proyectos/pais/crecer/"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/proyectos/pais/crecer/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/proyectos/pais/crecer/"
            />
          </MetaTags>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Proyectos",
                    item: "https://www.aporta.org.pe/proyectos/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: `Pais`,
                    item: `https://www.aporta.org.pe/proyectos/pais/`,
                  },
                  {
                    "@type": "ListItem",
                    position: 4,
                    name: `Crecer`,
                    item: `https://www.aporta.org.pe/proyectos/pais/crecer`,
                  },
                ],
              })}
            </script>
          </Helmet>
          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <br></br>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Proyectos
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos/pais"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Innovación Social País
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos/pais/crecer"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  Crecer
                </Link>
              </Breadcrumbs>
              <br></br>
              <Titlerich
                color={"var(--naranja"}
                colortext={"var(--negro)"}
                container={crecer.fields.tituloRich}
                colorbold={"var(--negro"}
              ></Titlerich>

              <br></br>
              <Sideimage
                image={crecer.fields.crecerRow1image.fields.file.url}
                colorb={"var(--naranja)"}
              >
                <Contentbar>{crecer.fields.crecerRow1text}</Contentbar>
              </Sideimage>
              <ImgEducacion src={Education} alt="crecer_educacion" />
              <Textrichcrecerintro
                texto={crecer.fields.crecerRow15text}
                clr={"var(--morado)"}
              ></Textrichcrecerintro>
              <br></br>
              <Historianorich
                title={crecer.fields.crecerRow2title}
                texto={crecer.fields.crecerRow2text}
                fontc={"var(--celeste)"}
                fontcb={"var(--celeste)"}
              >
                <Cardcontainer>
                  <img
                    src={crecer.fields.crecerRow2image.fields.file.url}
                    alt="desenrrollate"
                  />
                </Cardcontainer>
              </Historianorich>
              <br></br>
              <br></br>
              <Historianorichright
                title={crecer.fields.crecerRow3title}
                texto={crecer.fields.crecerRow3text}
                fontc={"var(--naranja)"}
                fontcb={"var(--naranja)"}
              >
                <Cardcontainer>
                  <img
                    src={crecer.fields.crecerRow3image.fields.file.url}
                    alt="desenrrollate imagen"
                  />
                </Cardcontainer>
              </Historianorichright>
              <br></br>
              <br></br>
              <Historianorich
                title={crecer.fields.crecerRow4title}
                texto={crecer.fields.crecerRow4text}
                fontc={"var(--verde)"}
                fontcb={"var(--verde)"}
              >
                <Cardcontainer>
                  <img
                    src={crecer.fields.crecerRow4image.fields.file.url}
                    alt="desenrrollate"
                  />
                </Cardcontainer>
              </Historianorich>
              <br></br>
              <Textrichcrecer
                texto={crecer.fields.creacerRow5text}
                clr={"var(--morado)"}
              ></Textrichcrecer>

              <Slidercrecer galeria={crecer.fields.galeria}></Slidercrecer>
              <br></br>
              <br></br>
              <Bottomsection
                clr={"var(--verde)"}
                titulo={crecer.fields.bottomtext}
                subtitulo={""}
              ></Bottomsection>
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
